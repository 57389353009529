import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { config } from './config';
import { getAuthenticatedHeaders } from '@/lib/authentication';
import { subscriptionStatusOptions as status } from '@/utils/paddle';

const { apiBaseUrl, graphqlUrl } = config;

//* Unauthentiated *//

export const fetchPaddlePrices = async ({ commit }, { priceId, priceType }) => {
  axios
    .get(`${apiBaseUrl}/paddle/prices/${priceId}`)
    .then((res) => {
      const { unit_price: paddlePrice, trial_period: trial } = res.data;

      if (priceType === 'month') {
        commit('SET_STATE_PROPERTY', {
          property: 'monthPrice',
          value: paddlePrice.amount / 100, // INFO: dividing with 100 because we receive prices in cents
        });
        commit('SET_STATE_PROPERTY', {
          property: 'monthCurrencyCode',
          value: paddlePrice.currency_code,
        });
        commit('SET_STATE_PROPERTY', {
          property: 'trial_interval_month',
          value: trial.interval,
        });
        commit('SET_STATE_PROPERTY', {
          property: 'trial_frequency_month',
          value: trial.frequency,
        });
      } else if (priceType === 'annual') {
        commit('SET_STATE_PROPERTY', {
          property: 'annualyPrice',
          value: paddlePrice.amount / 100,
        });
        commit('SET_STATE_PROPERTY', {
          property: 'annualyCurrencyCode',
          value: paddlePrice.currency_code,
        });
        commit('SET_STATE_PROPERTY', {
          property: 'trial_interval_annual',
          value: trial.interval,
        });
        commit('SET_STATE_PROPERTY', {
          property: 'trial_frequency_annual',
          value: trial.frequency,
        });
      }
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};

//* Requires authentiation *//

export const getDiscountInfoForSubscriptionId = async ({ commit }, { subscription_id }) => {
  const query = `{
    getPaddleSubscriptionById(subscription_id:"${subscription_id}") {
      discount {
        id,
        starts_at,
        ends_at
      }
      }
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(async (res) => {
      const discount = res.data.data?.getPaddleSubscriptionById?.discount;

      commit('SET_STATE_PROPERTY', {
        property: 'discount',
        value: discount,
      });

      // * Unauthenticated *//
      discount?.id &&
        axios.get(`${apiBaseUrl}/paddle/discounts/${discount?.id}`).then((response) => {
          if (response.data?.amount) {
            commit('SET_STATE_PROPERTY', {
              property: 'discountAmount',
              value: response.data.amount,
            });
          }
        });
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};
// eslint-disable-next-line no-unused-vars
export const createPaddleCustomer = async ({ commit }, { email, uid }) => {
  const query = `{
    createCustomer(
      email: "${email}"
      uid: "${uid}"
    )
  }`;
  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(() => {
      // const paddlePrice = res.data.data.getPaddlePriceById.unit_price;
      // console.log(res.data.data);
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};

export const fetchSubscriptionMethodTransaction = async ({ commit }, { subscriptionId }) => {
  try {
    const query = `{
      getSubscriptionMethodTransaction(subscriptionId:"${subscriptionId}") {
          id
        }
      }`;
    const response = await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());

    const responseData = response.data.data.getSubscriptionMethodTransaction;
    commit('SET_STATE_PROPERTY', {
      property: 'paddleTransactionId',
      value: responseData.id,
    });
  } catch (err) {
    console.error('Error fetching subscription method transaction:', err);
    // You may want to handle the error more robustly here, e.g., by committing an error state or returning a specific error message
    throw err; // Re-throw the error if you want the calling function to be aware of it
  }
};

export const cancelSubscription = async ({ dispatch }, { subscriptionId }) => {
  try {
    const query = `{
      cancelSubscription(subscriptionId:"${subscriptionId}")
    }`;
    const response = await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());
    const { errors } = response.data;
    if (errors) throw new Error('Error canceling subscription', { cause: errors });
    await dispatch('fetchUserProfile');
  } catch (err) {
    console.error('Error canceling subscription:', err);
    throw err;
  }
};

export function checkIsSubscriptionCanceled(userProfile) {
  const scheduledChange = userProfile?.scheduled_change;
  const subscriptionCanceled = userProfile?.canceled_at;
  return scheduledChange?.action === 'cancel' || subscriptionCanceled;
}

export function checkIsSubscriptionCanceledActive(userProfile) {
  const subscriptionCanceled = userProfile?.canceled_at;
  return subscriptionCanceled;
}

export function checkIsSubscriptionPastDue(userProfile) {
  return status.past_due.includes(userProfile?.subscriptionStatus);
}
