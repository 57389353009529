/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import markdownToTxt from 'markdown-to-txt';

import * as Sentry from '@sentry/browser';
import { toast } from 'vue3-toastify';
import { config } from './config';
import { cutString, getYearMonths } from '@/utils';
import { getMessageUsedIds } from '@/utils/references';
import { organizationHeader } from '@/lib/utils';
import { getAuthenticatedHeaders, getIdToken } from '@/lib/authentication';

const { defaultSources } = require('../../lib/chatSources');

const { graphqlUrl, graphqlUrlPublic, apiBaseUrl } = config;

/**
 * Fetches the chat history list from the server.
 * @param {Object} context - The Vuex context object.
 */

export const getChatHistoryList = async ({ commit, state }) => {
  const query = `{
    listChatHistory(uid: "${state.userProfile.uid || state.chatRandomUserId}") {
      sessionId
      timestamp
      title
      isChatShared
    }
  }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then((response) => {
      commit('SET_STATE_PROPERTY', {
        property: 'chatHistory',
        value: response.data.data.listChatHistory,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

const uniqueByStringify = (array) => {
  const seen = {};
  return array.filter((item) => {
    const key = JSON.stringify(item);
    return Object.prototype.hasOwnProperty.call(seen, key) ? false : (seen[key] = true);
  });
};

/**
 * Loads the chat history from the server.
 * @param {Object} context - The Vuex context object.
 * @param {string} sessionId - The session ID.
 */
export const loadChatHistory = async ({ commit }, sessionId) => {
  commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: true });
  const query = `{
    loadChatHistory(sessionId:"${sessionId}"){
      citations {
        id
        pmid
        title
        abstract
        first_author
        journal
        impact_factor
        publication_date
        doi
        ml_p
        ml_i
        ml_c
        ml_o
        ml_b
        ml_n
        ml_type
        document_id
        content
        date
        page
        source
        document
        path
        type
      }
      history {
        user
        content
        responseQuality
        feedback
      }
    }
  }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then((response) => {
      commit('SET_STATE_PROPERTY', { property: 'chatCitations', value: [] });
      commit('SET_STATE_PROPERTY', { property: 'chatSearchQuery', value: '' });
      commit('SET_STATE_PROPERTY', { property: 'chatMainQuestion', value: '' });

      const { citations, history } = response.data.data.loadChatHistory;
      commit('SET_STATE_PROPERTY', { property: 'chatUsedCitations', value: citations });
      commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: history });

      commit('SET_STATE_PROPERTY', { property: 'chatSessionId', value: sessionId });
      commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
      commit('SET_STATE_PROPERTY', {
        property: 'chatCitationsMenuOpened',
        value: citations.length > 0,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

/**
 * Loads the chat history from the server.
 * @param {Object} context - The Vuex context object.
 * @param {string} sessionId - The session ID.
 */
export const loadSharedChatHistory = async ({ commit }, sessionId) => {
  commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: true });
  const query = `{
    loadSharedChatHistory(sessionId:"${sessionId}"){
      citations {
        id
        pmid
        title
        abstract
        first_author
        journal
        impact_factor
        publication_date
        doi
        ml_p
        ml_i
        ml_c
        ml_o
        ml_b
        ml_n
        ml_type
        document_id
        content
        date
        page
        source
        document
        path
        type
      }
      history {
        user
        content
        responseQuality
        feedback
      }
    }
  }`;
  axios
    .post(graphqlUrlPublic, { query })
    .then((response) => {
      if (!response?.data?.data?.loadSharedChatHistory) {
        commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
        // TODO: Fetch translation
        toast.info('Chat is no longer shared');
        return;
      }

      commit('SET_STATE_PROPERTY', { property: 'chatCitations', value: [] });
      commit('SET_STATE_PROPERTY', { property: 'chatSearchQuery', value: '' });
      commit('SET_STATE_PROPERTY', { property: 'chatMainQuestion', value: '' });

      const { citations, history } = response.data.data.loadSharedChatHistory;
      commit('SET_STATE_PROPERTY', { property: 'chatUsedCitations', value: citations });
      commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: history });

      commit('SET_STATE_PROPERTY', { property: 'chatSessionId', value: uuidv4() });
      commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
      commit('SET_STATE_PROPERTY', {
        property: 'chatCitationsMenuOpened',
        value: citations.length > 0,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

// eslint-disable-next-line no-unused-vars
export const shareChat = async ({ commit, dispatch, state }, { sessionId, isChatShared }) => {
  // console.log('isChatShared', isChatShared);
  const query = `{
    shareChat(sessionId:"${sessionId}", isChatShared:${isChatShared})
  }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    // .then((response) => {
    //   const { responseData } = response.data.data;
    // })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

/**
 * Adds a new message to the chat.
 * @param {Object} context - The Vuex context object.
 */

export const addMessage = async ({ commit, dispatch, state, getters }) => {
  const chatNewMessageFromLocalStorage = localStorage.getItem('chatNewMessage');

  if (chatNewMessageFromLocalStorage) {
    commit('SET_STATE_PROPERTY', {
      property: 'chatNewMessage',
      value: chatNewMessageFromLocalStorage,
    });
    localStorage.removeItem('chatNewMessage');
  }

  if (state.chatNewMessage && state.chatNewMessage.trim() !== '' && !state.chatLoading) {
    if (state.chatUsePubmed || getters.chatSelectedSources.length > 0) {
      dispatch('submitQuestion', state.chatNewMessage.trim());
    } else {
      commit('SET_STATE_PROPERTY', {
        property: 'chatMessages',
        value: state.chatMessages.concat([
          {
            user: 'USER',
            content: state.chatNewMessage.trim(),
            responseQuality: 0,
            feedback: '',
          },
          {
            user: 'ASSISTANT',
            content: 'Please select at least one source.',
            responseQuality: 0,
            feedback: '',
          },
        ]),
      });
    }
    commit('SET_STATE_PROPERTY', { property: 'chatNewMessage', value: '' });
  }
};

export const regenerateMessage = async ({ commit, state, dispatch }) => {
  // console.log(state.chatMessages);
  const chatMessages = state.chatMessages;
  const numMessages = chatMessages?.length || 0;
  if (numMessages > 1) {
    const previousMessages = chatMessages.slice(0, numMessages - 2);
    let usedMessageIds = new Set();
    for (let message of previousMessages) {
      if (message.user === 'ASSISTANT') {
        const ids = getMessageUsedIds(message.content);
        usedMessageIds = new Set([...usedMessageIds, ...ids]);
      }
    }

    commit('SET_STATE_PROPERTY', { property: 'chatUsedIds', value: Array.from(usedMessageIds) });

    const chatUsedCitations = state.chatUsedCitations.filter((m) => usedMessageIds.has(m.id));

    commit('SET_STATE_PROPERTY', { property: 'chatUsedCitations', value: chatUsedCitations });

    const userMessage = chatMessages[numMessages - 2];
    if (userMessage.user === 'USER') {
      // const lastAssistantMessage = chatMessages[numMessages - 1];

      commit('SET_STATE_PROPERTY', {
        property: 'chatMessages',
        value: chatMessages.slice(0, numMessages - 2),
      });
      // console.log('regenerateMessage', userMessage.content); // TODO remove / use for debugging
      dispatch('submitQuestion', userMessage.content);
    }
  }
};

/**
 * Submits a question to the server.
 * @param {Object} context - The Vuex context object.
 * @param {string} message - The question to submit.
 */

export const submitQuestion = async ({ commit, dispatch, state, getters }, message) => {
  const startTime = performance.now();
  commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'questionLoading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'searchLoading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'chatLoadingDocs', value: true });
  commit('SET_STATE_PROPERTY', { property: 'chatLoadingQuery', value: true });
  commit('SET_STATE_PROPERTY', { property: 'chatAnswerParts', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatSearchQuery', value: '' });

  const isAdvanced = getters.isPro && state.chatUsePubmed;

  const filterValues = {
    chatGptMode: state.chatGptMode,
    chatElaborateMode: state.chatElaborateMode,
    ageInMonths: getYearMonths(state.chatFilters.sinceYear),
    studyTypes: state.chatFilters.studyTypes,
    impactFactor: state.chatFilters.impactFactor,
    sampleSize: +(state.chatFilters.sampleSize || 0),
    isPro: true,
  };

  const payload = {
    question: message,
    history: state.chatMessages,
    sources: getters.chatSelectedSources,
    userLanguage: state.userProfile.userLanguage || state.locale,
    sourceLanguage: state.chatSourceLanguage,
    layman: state.chatLayman,
    source: state.chatUseSource,
    studyTypes: state.s_article_options.map((el) => el.key),
    impactFactor: false,
    sampleSize: 0,
    ageInMonths: null,
    chatGptMode: false,
    chatElaborateMode: false,
  };

  if (isAdvanced) {
    Object.assign(payload, filterValues);
    const currentWeekProUsage = state.currentWeekProUsage + 1;
    if (currentWeekProUsage === state.maxWeekProUsage) {
      dispatch('trackEvent', {
        event: 'reachedMaxWeekProUsage',
        values: {
          maxWeekProUsage: state.maxWeekProUsage,
        },
      });
    }
    commit('SET_STATE_PROPERTY', { property: 'currentWeekProUsage', value: currentWeekProUsage });
  }

  const bufChatMessages = state.chatMessages;
  bufChatMessages.push({ user: 'USER', content: message });
  bufChatMessages.push({
    user: 'ASSISTANT',
    content: '',
    responseQuality: 0,
    feedback: '',
  });
  commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: bufChatMessages });

  fetch(`${config.apiBaseUrl}/${state.chatUsePubmed ? 'chat' : 'library'}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getIdToken()}`,
    },
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      if (response.status === 401) {
        const messagesBuf = state.chatMessages;
        messagesBuf[messagesBuf.length - 1].content = '❗Unauthorized';
        commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
        commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
        commit('SET_STATE_PROPERTY', { property: 'chatLoadingDocs', value: false });
        commit('SET_STATE_PROPERTY', { property: 'searchLoading', value: false });
        commit('SET_STATE_PROPERTY', { property: 'questionLoading', value: false });

        toast.error('Unauthorized', { autoClose: 5000 });
        return;
      }

      commit('SET_STATE_PROPERTY', { property: 'questionLoading', value: false });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let data = '';

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        data += decoder.decode(value);

        let jsonValues = [];
        try {
          data = data.replace(/}{/g, '},{');
          jsonValues = JSON.parse(`[${data}]`);
          data = '';
        } catch (error) {
          if (!data.startsWith('{"type":')) {
            Sentry.captureException(new Error(`JSON parsing error: ${data}`));
          }
          // eslint-disable-next-line no-continue
          continue;
        }

        for (const jsonValue of jsonValues) {
          if (jsonValue.type === 'answer_part') {
            const answerPartsBuf = state.chatAnswerParts;

            answerPartsBuf.push(jsonValue);
            const sorted_answer = answerPartsBuf.sort((a, b) => a.index - b.index);
            const messagesBuf = state.chatMessages;
            messagesBuf[messagesBuf.length - 1].content = sorted_answer.map((a) => a.text).join('');
            commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
            if (state.searchLoading) {
              commit('SET_STATE_PROPERTY', { property: 'searchLoading', value: false });
            }
          }
          if (jsonValue.type === 'done') {
            commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });

            const messagesBuf = state.chatMessages;
            messagesBuf[messagesBuf.length - 1].content = jsonValue.answer;
            commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });

            commit('SET_STATE_PROPERTY', {
              property: 'chatCitations',
              value: uniqueByStringify(state.chatCitations.concat(state.chatDocs)),
            });

            const referencesCount = getMessageUsedIds(jsonValue.answer).length;
            const endTime = performance.now();
            const elapsedTime = (endTime - startTime) / 1000;
            const wordsCount = jsonValue.answer.split(' ').length;
            dispatch('trackEvent', {
              event: 'ChatResponseProvided',
              values: {
                reference_count: referencesCount,
                elapsed_time: elapsedTime,
                length_words: wordsCount,
                content: cutString(markdownToTxt(jsonValue.answer)),
              },
            });
            dispatch('saveSession');
            break;
          }
          if (jsonValue.type === 'search_string') {
            commit('SET_STATE_PROPERTY', {
              property: 'chatSearchQuery',
              value: jsonValue.search_string,
            });
            commit('SET_STATE_PROPERTY', { property: 'chatLoadingQuery', value: false });
          }
          if (jsonValue.type === 'main_question') {
            commit('SET_STATE_PROPERTY', {
              property: 'chatMainQuestion',
              value: jsonValue.main_question,
            });

            commit('SET_STATE_PROPERTY', { property: 'chatLoadingQuery', value: false });
          }
          if (jsonValue.type === 'action_question') {
            commit('SET_STATE_PROPERTY', {
              property: 'chatLoadingQuery',
              value: false,
            });
          }
          if (jsonValue.type === 'all_docs') {
            commit('SET_STATE_PROPERTY', {
              property: 'chatDocs',
              value: state.chatDocs.concat(jsonValue.docs),
            });
            commit('SET_STATE_PROPERTY', { property: 'chatLoadingDocs', value: false });
          }
          if (jsonValue.type === 'failed') {
            const messagesBuf = state.chatMessages;
            messagesBuf[messagesBuf.length - 1].content = jsonValue.answer;
            commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
            commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
            commit('SET_STATE_PROPERTY', { property: 'chatLoadingDocs', value: false });
            commit('SET_STATE_PROPERTY', { property: 'searchLoading', value: false });
          }
          if (jsonValue.type === 'error') {
            const messagesBuf = state.chatMessages;
            messagesBuf[messagesBuf.length - 1].content =
              '❗' + jsonValue.message + ' 😢 Please try again.';
            commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
            commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
            commit('SET_STATE_PROPERTY', { property: 'chatLoadingDocs', value: false });
            commit('SET_STATE_PROPERTY', { property: 'searchLoading', value: false });
            commit('SET_STATE_PROPERTY', { property: 'questionLoading', value: false });

            toast.error(jsonValue.message, { autoClose: 5000 });
          }
        }
      }

      const chatCitationsMenuOpened = !!(
        state.chatCitations.length || state.chatUsedCitations.length
      );

      if (chatCitationsMenuOpened) {
        commit('SET_STATE_PROPERTY', {
          property: 'chatCitationsMenuOpened',
          value: chatCitationsMenuOpened,
        });
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      // console.error(`Error: ${error}`);
      commit('SET_STATE_PROPERTY', { property: 'chatLoading', value: false });
    });
};

export const getHistoryUsedIds = (history) => {
  const ids = [];
  if (!Array.isArray(history)) return ids;

  history.forEach((m) => {
    const matches = getMessageUsedIds(m.content);
    ids.push(...matches);
  });
  // store only unique values:
  return [...new Set(ids)];
};

/**
 * Saves the current chat session.
 * @param {Object} context - The Vuex context object.
 */
export const saveSession = async ({ commit, dispatch, state }) => {
  const history = state.chatMessages;
  const chatUsedIds = getHistoryUsedIds(history);

  let otherReferences = state.chatCitations;
  let references = state.chatUsedCitations;

  const missingReferences = [];

  chatUsedIds.forEach((id) => {
    const citation = otherReferences.find((c) => c.id === id); // other references
    if (citation && !references.find((c) => c.id === id)) {
      missingReferences.push(citation);
    }
  });

  references = [...references, ...missingReferences];

  // Check that we are not trying to save empty citations
  if (chatUsedIds.length && references.length === 0) {
    toast.info('Chat with empty citations cannot be saved');
    return;
  }

  commit('SET_STATE_PROPERTY', { property: 'chatUsedCitations', value: references });

  const referenceIds = new Set(references.map((c) => c.id));

  otherReferences = otherReferences.filter((c) => !referenceIds.has(c.id));

  commit('SET_STATE_PROPERTY', { property: 'chatCitations', value: otherReferences });

  const unquoted = JSON.stringify({ citations: references, history }).replace(/"([^"]+)":/g, '$1:');

  // TODO: remove unregisteredUserEmail from api call, which is no longer used
  const query = `{
      saveChat(uid: "${state.userProfile.uid || state.chatRandomUserId}", sessionId: "${
    state.chatSessionId
  }",
      unregisteredUserEmail: "${''}",
      session: ${unquoted})
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then((/*response*/) => {
      dispatch('getChatHistoryList');
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });

  axios
    .post(
      `${apiBaseUrl}/chat/sessions`,
      {
        sessionId: state.chatSessionId,
        session: { citations: state.chatUsedCitations, history: state.chatMessages },
      },
      await getAuthenticatedHeaders(organizationHeader(state.organization))
    )
    .catch((error) => {
      Sentry.captureException(error);
      console.log(error);
    });
};

/**
 * Initializes the chat copy success state.
 * @param {Object} context - The Vuex context object.
 */
export const initializeCopySuccess = ({ commit, state }) => {
  let bufChatMessages = Array.isArray(state.chatMessages) ? state.chatMessages : [];
  bufChatMessages = bufChatMessages.reduce((acc, _, index) => ({ ...acc, [index]: false }), {});
  commit('SET_STATE_PROPERTY', { property: 'chatCopySuccess', value: bufChatMessages });
};

/**
 * Handles the copy event.
 * @param {Object} context - The Vuex context object.
 * @param {number} index - The index of the message.
 */

export const onCopy = ({ state, commit }, index) => {
  commit('SET_STATE_PROPERTY', {
    property: 'chatCopySuccess',
    value: { ...state.chatCopySuccess, [index]: true },
  });
  setTimeout(() => {
    commit('SET_STATE_PROPERTY', {
      property: 'chatCopySuccess',
      value: { ...state.chatCopySuccess, [index]: false },
    });
  }, 1000);
};

/**
 * Navigates to the next page.
 * @param {Object} context - The Vuex context object.
 */
export const nextPage = ({ commit, state }) => {
  commit('SET_STATE_PROPERTY', { property: 'chatPdfPage', value: state.chatPdfPage + 1 });
};

/**
 * Navigates to the previous page.
 * @param {Object} context - The Vuex context object.
 */
export const prevPage = ({ state, commit }) => {
  if (state.chatPdfPage > 1) {
    commit('SET_STATE_PROPERTY', { property: 'chatPdfPage', value: state.chatPdfPage - 1 });
  }
};

/**
 * Navigates to the particular page.
 * @param {Object} context - The Vuex context object.
 */
export const updatePage = ({ commit }, payload) => {
  commit('SET_STATE_PROPERTY', { property: 'chatPdfPage', value: payload });
};

/**
 * Sends feedback to the server.
 * @param {Object} context - The Vuex context object.
 */
export const sendFeedback = async ({ commit, dispatch, state }) => {
  const messagesBuf = state.chatMessages;
  messagesBuf[state.chatFeedbackQuestion].feedback = state.chatFeedback;
  commit('SET_STATE_PROPERTY', {
    property: 'chatMessages',
    value: messagesBuf,
  });
  commit('SET_STATE_PROPERTY', { property: 'chatAskFeedback', value: false });
  commit('SET_STATE_PROPERTY', { property: 'chatFeedback', value: '' });
  commit('SET_STATE_PROPERTY', { property: 'chatFeedbackQuestion', value: null });

  dispatch('saveSession');
};

/**
 * Cancels the feedback.
 * @param {Object} context - The Vuex context object.
 */
export const cancelFeedback = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', { property: 'chatAskFeedback', value: false });
};

/**
 * Gives a thumbs up to a message.
 * @param {Object} context - The Vuex context object.
 * @param {number} index - The index of the message.
 */
export const thumbsUp = async ({ commit, dispatch, state }, index) => {
  const messagesBuf = state.chatMessages;
  messagesBuf[index].responseQuality = 2;
  commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
  dispatch('saveSession');
};

/**
 * Gives a thumbs down to a message.
 * @param {Object} context - The Vuex context object.
 * @param {number} index - The index of the message.
 */
export const thumbsDown = async ({ commit, dispatch, state }, index) => {
  const messagesBuf = state.chatMessages;
  messagesBuf[index].responseQuality = 1;
  commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: messagesBuf });
  commit('SET_STATE_PROPERTY', { property: 'chatAskFeedback', value: true });
  commit('SET_STATE_PROPERTY', { property: 'chatFeedbackQuestion', value: index });
  dispatch('saveSession');
};

/**
 * Navigates to the next page in the chat history.
 * @param {Object} context - The Vuex context object.
 */
export const nextPageHistory = async ({ state, commit }) => {
  if (state.chatCurrentPageHistory < state.chatMaxPageHistory) {
    this.currentPageHistory++;
    commit('SET_STATE_PROPERTY', {
      property: 'chatCurrentPageHistory',
      value: state.chatCurrentPageHistory++,
    });
  }
};

/**
 * Navigates to the previous page in the chat history.
 * @param {Object} context - The Vuex context object.
 */
export const prevPageHistory = async ({ state, commit }) => {
  if (state.chatCurrentPageHistory > 0) {
    commit('SET_STATE_PROPERTY', {
      property: 'chatCurrentPageHistory',
      value: state.chatCurrentPageHistory--,
    });
  }
};

/**
 * Starts a new chat session.
 * @param {Object} context - The Vuex context object.
 */
export const newChat = async ({ commit, state }) => {
  const chatMessagesBuf = [{ user: 'ASSISTANT', content: state.chatWelcomeMessage }];

  commit('SET_STATE_PROPERTY', { property: 'chatMessages', value: chatMessagesBuf });
  commit('SET_STATE_PROPERTY', { property: 'chatSessionId', value: uuidv4() });
  commit('SET_STATE_PROPERTY', { property: 'chatSearchQuery', value: '' });
  commit('SET_STATE_PROPERTY', { property: 'chatMainQuestion', value: '' });
  commit('SET_STATE_PROPERTY', { property: 'chatUsedIds', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatCitations', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatUsedCitations', value: [] });
};

/**
 * Deletes a chat session.
 * @param {Object} context - The Vuex context object.
 * @param {string} sessionId - The ID of the session to delete.
 */
export const deleteChat = async ({ commit, dispatch, state }, sessionId) => {
  const bufChatDeletingBusy = state.chatDeletingBusy;
  bufChatDeletingBusy[sessionId] = true;
  commit('SET_STATE_PROPERTY', { property: 'chatDeletingBusy', value: bufChatDeletingBusy });

  // console.log('bufChatDeletingBusy', bufChatDeletingBusy);
  const query = `{
  deleteChatHistory(uid: "${
    state.userProfile.uid || state.chatRandomUserId
  }", sessionId: "${sessionId}")
}`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then((/*response*/) => {
      dispatch('getChatHistoryList');
      bufChatDeletingBusy[sessionId] = false;
      commit('SET_STATE_PROPERTY', { property: 'chatDeletingBusy', value: bufChatDeletingBusy });
    })
    .catch((error) => {
      Sentry.captureException(error);
      // console.log(error);
      bufChatDeletingBusy[sessionId] = false;
      commit('SET_STATE_PROPERTY', { property: 'chatDeletingBusy', value: bufChatDeletingBusy });
    });
};

export const fetchCitationPath = async (citation) => {
  const { source, path } = citation;
  const sources = defaultSources();
  // console.log('citationPath', source, path, sources);
  if (sources.includes(source)) {
    return path;
  } else {
    // Fetch signed url for source
    const response = await axios.post(
      `${apiBaseUrl}/library/generate-signed-url`,
      { path },
      await getAuthenticatedHeaders()
    );
    return response.data.data.signedUrl;
  }
};

/**
 * Displays the evidence for a given citation.
 * @param {Object} context - The Vuex context object.
 * @param {string} id - The ID of the citation to show evidence for.
 */
export const showEvidence = async ({ commit, state }, { id, other = false }) => {
  const citationSource = other ? state.chatCitations : state.chatUsedCitations;
  const citation = citationSource.find((c) => c.id === id);

  // console.log('citation', citation);

  if (citation.type === 'pdf') {
    const citationPath = await fetchCitationPath(citation);
    // console.log('citationPath', citationPath);
    citation.path = citationPath;
  }

  if (citation) {
    commit('SET_STATE_PROPERTY', {
      property: 'chatSelectedCitation',
      value: {
        data: citation,
        other,
      },
    });
    commit('SET_STATE_PROPERTY', { property: 'chatIsExpanded', value: true });

    if (citation.page) {
      commit('SET_STATE_PROPERTY', { property: 'chatPdfPage', value: citation.page });
    }
  }
  return citation;
};

export const resetChatSources = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', { property: 'chatSelectedSourcesInternational', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatSelectedSourcesRegional', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatSelectedSourcesCustom', value: [] });
  commit('SET_STATE_PROPERTY', { property: 'chatToggleUsePubmed', value: true });
};
